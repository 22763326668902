<template>
	<div id="body">
		<div id="main">
			<div class="main_header">
				<div class="main_header_box">
					<div class="main_header_box_left">
						<span>分类：</span>
					</div>
					<div class="main_header_right">
						<div class="main_header_right_txt">
							全部
						</div><div class="main_header_right_txt">
							伙伴课程
						</div><div class="main_header_right_txt">
							推广视频
						</div>
					</div>
				</div>
				<div class="main_header_box">
					<div class="main_header_box_left">
						<span>行业：</span>
					</div>
					<div class="main_header_right">
						<div class="main_header_right_txt">
							全部
						</div><div class="main_header_right_txt">
							ERP系统
						</div><div class="main_header_right_txt">
							OA办公
						</div><div class="main_header_right_txt">
							智能报修系统
						</div><div class="main_header_right_txt">
							仓储进销存
						</div><div class="main_header_right_txt">
							商会管理系统
						</div>
						<div class="main_header_right_txt">
							门店管理系统
						</div>
					</div>
				</div>
				<div class="main_header_box">
					<div class="main_header_box_left">
						<span>搜索：</span>
					</div>
					<div class="main_header_right">
						<el-input  v-model="txt" placeholder="请输入内容"></el-input>
						<el-button size='small' style='margin-left: 10px;' type="primary">查询</el-button>
					</div>
				</div>
			</div>
			<div class="mian_main">
				<div v-for="(iten,i) in 7" class="mian_main_flex" @click="$router.push('/HanBookVideo')">
					<img src="https://img2.baidu.com/it/u=331362266,3772279129&fm=253&fmt=auto&app=120&f=JPEG?w=750&h=500" >
					<p>茶饮供应链亮点解析茶饮供应链亮点解析茶饮供应链亮点解析</p>
					<span>1.19茶饮直播课程精彩回放：茶饮供应链亮点解析</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				txt:""
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	#main{
		width: 80%;
		min-height: 79vh;
		background-color:#EFF1F4;
		margin: auto;
		margin-top: 80px;
		border-radius: 10px;
		.main_header{
			width: 96%;
			margin: auto;
			// background-color: #999;
			.main_header_box{
				height: 60px;
				width: 100%;
				border-bottom: 1px solid #FFFFFF;
				display: flex;
				align-items: center;
				.main_header_box_left{
					width: 8%;
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					span{
						cursor: pointer;
					}
				}
				.main_header_right{
					display: flex;
					align-items: center;
					.main_header_right_txt{
						margin-left: 15px;
						font-size: 17px;
						cursor: pointer;
					}
				}
			}
		}
		.mian_main{
			width: 96%;
			margin: auto;
			margin-top: 20px;
			display: flex;
			flex-wrap: wrap;
			.mian_main_flex{
				width:19%;
				height: 200px;
				margin-left: 1%;
				margin-bottom: 20px;
				text-align: center;
				cursor: pointer;
				img{
					max-width: 90%;
					min-width: 85%;
					height: 70%;
				}
				p{
					text-align: left;
					width: 90%;
					margin-left: 5%;
					font-weight: bold;
					margin-bottom: 0;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-top: 5px;
				}
				span{
					font-size: 14px;
					font-weight: bold;
					color: 	#666666;
					display: inline-block;
					width: 90%;
					margin-left: 5%;
					text-align: left;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-top: 8px;
				}
			}
		}
	}
</style>
